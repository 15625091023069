import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from 'helpers/Firebase';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  type DocumentData,
  Timestamp,
} from 'firebase/firestore';
import { isEmpty, handleUploadFiles } from 'helpers/genericTsHelpers';
import { FirebaseError } from 'firebase/app';
import { COLLECTION_PROVIDERS_TICKETS, ProviderTicketStatus, type ProviderTicket } from '@thermokracy/model';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getDownloadUrls } from 'helpers/firebaseHelpers';

interface ProviderTicketsState {
  tickets: ProviderTicket[];
  error: string;
  ticketsLoading: boolean;
}

const initialState: ProviderTicketsState = {
  tickets: [],
  error: '',
  ticketsLoading: true,
};

export const orderColumns = [
  { column: 'title', label: 'Title' },
  { column: 'category', label: 'Category' },
  { column: 'status', label: 'Status' },
];

const ConvertFirebaseDocumentToProviderTicket = async (docu: DocumentData, id: string): Promise<ProviderTicket> => {
  const {
    communityId,
    openerId,
    openerName,
    title,
    description,
    status,
    category,
    createDate,
    providerId,
    providerName,
    extraInfo,
    images,
  } = docu;
  const formattedCreateDate = createDate.toDate();
  const cdate = `${formattedCreateDate.getMonth() + 1}/${formattedCreateDate.getDate()}/${formattedCreateDate.getFullYear()}`;

  let resolvedImages: string[] = [];
  if (images != null && Array.isArray(images)) {
    resolvedImages = await getDownloadUrls(images as string[]);
  }

  return {
    communityId,
    openerId,
    openerName,
    category,
    createDate: cdate,
    title,
    description,
    id,
    providerId,
    providerName,
    status,
    ...(extraInfo != null ? { extraInfo } : {}),
    ...(resolvedImages.length > 0 ? { images: resolvedImages } : {}),
  };
};

interface GetProvidersTicketListParams {
  communityId?: string;
  openerId?: string;
  providerId?: string;
}

export const getProvidersTicketList = createAsyncThunk<
  ProviderTicket[],
  GetProvidersTicketListParams,
  { rejectValue: { error: string } }
>(
  'providersTickets/getProvidersTicketList',
  async ({ communityId, openerId, providerId }: GetProvidersTicketListParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, COLLECTION_PROVIDERS_TICKETS);
      const filters = [];
      if (!isEmpty(communityId)) filters.push(where('communityId', '==', communityId));
      if (!isEmpty(openerId)) filters.push(where('openerId', '==', openerId));
      if (!isEmpty(providerId)) filters.push(where('providerId', '==', providerId));

      const q = query(ticketsRef, ...filters);
      const ticketsSnapshot = await getDocs(q);
      const documents = await Promise.all(
        ticketsSnapshot.docs.map(async (docu) => {
          return ConvertFirebaseDocumentToProviderTicket(docu.data(), docu.id);
        }),
      );

      return documents;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface AddProviderTicketParams {
  ticket: Omit<ProviderTicket, 'id' | 'status' | 'createDate'>;
  selectedFiles: File[];
}

export const addProviderTicket = createAsyncThunk<
  ProviderTicket,
  AddProviderTicketParams,
  { rejectValue: { error: string } }
>(
  'providerTickets/addProviderTicketItem',
  async ({ ticket, selectedFiles }: AddProviderTicketParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, COLLECTION_PROVIDERS_TICKETS);
      const newTicket = {
        ...ticket,
        status: ProviderTicketStatus.PENDING,
        createDate: serverTimestamp(),
      };

      const newTicketRef = await addDoc(ticketsRef, newTicket);

      const newTicketId = newTicketRef.id;

      const docRefImages = doc(db, COLLECTION_PROVIDERS_TICKETS, newTicketId);

      // TODO: Do we need this? im pretty sure we generate this now on the fly
      if (selectedFiles.length > 0) {
        const newFieldData = {
          carpeta: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/Tickets%2F${newTicketId}`,
        };
        await updateDoc(docRefImages, newFieldData);
        // La carpeta no la vamos a necesitar
        const results = await handleUploadFiles(newTicketId, selectedFiles, COLLECTION_PROVIDERS_TICKETS);
        const storage = getStorage();
        const downloadUrls = await Promise.all(
          results.map(async (result) => {
            const storageRef = ref(storage, `${COLLECTION_PROVIDERS_TICKETS}/${newTicketId}/${result.ref.name}`);
            return storageRef.fullPath;
          }),
        );
        await updateDoc(docRefImages, {
          images: downloadUrls,
        });
      }

      return {
        ...newTicket,
        id: newTicketId,
        createDate:
          newTicket?.createDate instanceof Timestamp
            ? newTicket.createDate.toDate().toLocaleDateString()
            : new Date().toLocaleDateString(),
      } as const as ProviderTicket;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface GetProviderTicketDetailsParams {
  ticketId: string;
}
export const getProviderTicketDetails = createAsyncThunk<
  ProviderTicket,
  GetProviderTicketDetailsParams,
  { rejectValue: { error: string } }
>('tickets/getProviderTicketDetails', async ({ ticketId }: GetProviderTicketDetailsParams, { rejectWithValue }) => {
  try {
    const ticketRef = doc(db, COLLECTION_PROVIDERS_TICKETS, ticketId);
    const ticketDoc = await getDoc(ticketRef);
    if (ticketDoc.exists()) {
      return await ConvertFirebaseDocumentToProviderTicket(ticketDoc.data(), ticketDoc.id);
    }
    return rejectWithValue({ error: 'Ticket does not exist' });
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      return rejectWithValue({ error: error.message });
    }
    return rejectWithValue({ error: 'An unknown error occurred' });
  }
});

interface UpdateProviderTicketFieldsParams {
  ticketIds: string;
  fieldsToUpdate: Partial<ProviderTicket>; // Partial allows updating one or more fields of the ticket
}

export const updateProviderTicketFields = createAsyncThunk<
  ProviderTicket,
  UpdateProviderTicketFieldsParams,
  { rejectValue: { error: string } }
>(
  'tickets/updateTicketFields',
  async ({ ticketIds: ticketId, fieldsToUpdate }: UpdateProviderTicketFieldsParams, { rejectWithValue }) => {
    try {
      const ticketDocRef = doc(db, COLLECTION_PROVIDERS_TICKETS, ticketId);

      await updateDoc(ticketDocRef, fieldsToUpdate);

      const ticketDocSnapshot = await getDoc(ticketDocRef);
      const ticket = ticketDocSnapshot.data();
      const updatedTicketDetails: Record<string, unknown> = { ...ticket, ...fieldsToUpdate };

      return await ConvertFirebaseDocumentToProviderTicket(updatedTicketDetails, ticketId);
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

const providerTicketsSlice = createSlice({
  name: 'providerTickets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersTicketList.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(getProvidersTicketList.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
      })
      .addCase(getProvidersTicketList.rejected, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addProviderTicket.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(addProviderTicket.fulfilled, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addProviderTicket.rejected, (state) => {
        state.ticketsLoading = false;
      });
  },
});
export default providerTicketsSlice.reducer;
