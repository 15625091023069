import { type CommunityRef } from './community';

// TODO: Usar un type literal o un enum
export enum UserRole {
  Admin = 0,
  Homeowner = 1,
  Provider = 2,
  HOA = 3,
}

export type UserRoleKeys = keyof typeof UserRole;
export type UserRoleValues = (typeof UserRole)[keyof typeof UserRole];

export interface UserHomeownerProviderRef {
  id: string;
  myTemperature: number;
  type: 'recurrentJob' | 'uniqueJob';
  state: string;
}
/**
 * Part of the `User` collection
 */
export interface UserHomeowner {
  address: string;
  community: CommunityRef;
  email: string;
  myProviders?: UserHomeownerProviderRef[];
  myProvidersDeleted?: UserHomeownerProviderRef[];
  name: string;
  phone?: string;
  photo?: string;
  role: UserRoleValues;
}

export interface UserProvider {
  role: UserRoleValues;
  name: string;
  email: string;
  temperature: number;
  suggesterId: string;
  activeCommunities: string[];
  inactiveCommunities: string[];
  /**
   * List of community engagements
   *
   * @deprecated needs to be renamed to something better ie `communityEngagements`1
   */
  community: Array<{
    id: string;
    active: boolean;
    temperature: number;
  }>;
  services: string[];
}
