/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FIREBASE_COMMUNITIES_COLLECTION,
  FIREBASE_HOAPROVIDERS_COLLECTION,
  FIREBASE_TRACKCHANGESTEMPERATURE_COLLECTION,
  FIREBASE_TRACKINTERESDEUSUARIOS_COLLECTION,
  FIREBASE_TRACKREVIEWSOFPROVIDERS_COLLECTION,
  FIREBASE_MASSIVEMESSAGESHOATOHOMEOWNER_COLLECTION,
  FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION,
  FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION,
  FIREBASE_NOTIFICATIONS_COLLECTION,
  FIREBASE_OUTGOINGEMAIL_COLLECTION,
  FIREBASE_TICKETS_COLLECTION,
  FIREBASE_TICKETSTOPROVIDERS_COLLECTION,
  FIREBASE_TICKETTOPICS_COLLECTION,
  FIREBASE_USERREQUEST_COLLECTION,
  FIREBASE_USERS_COLLECTION,
  FIREBASE_USERSHOA_COLLECTION,
  FIREBASE_USERSPROVIDERS_COLLECTION,
} from 'constants/defaultValuesFirebase';
import { v4 as uuidv4 } from 'uuid';
import { db } from 'helpers/Firebase';
import {
  doc,
  collection,
  updateDoc,
  getDocs,
  getDoc,
  type DocumentData,
  type QueryDocumentSnapshot,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  setDoc,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { type Community } from 'types/Community';
import { type Provider } from 'types/Provider';
import { defaultDebugHandler, defaultErrorHandler } from './errorHandlingHelper';
import { getTimeStamp, handleUploadFiles } from './genericTsHelpers';

// Add the following enum definition somewhere near the top of the file (or before the EmailData interface)
export enum EmailTemplate {
  RequestJoinCommunity = 'requestJoinCommunity',
  ConfirmationRequestJoinCommunity = 'confirmationRequestJoinCommunity',
  HOADeleteAccount = 'hoaDeleteAccount',
  HoaAssignTicketToProvider = 'hoaAssignTicketToProvider',
  HoaMassiveMail = 'hoaMassiveMail',
  SetStatusTicket = 'setStatusTicket',
  ProviderInvitation = 'providerInvitation',
  RequestMemberCode = 'requestMemberCode',
  ResponseDeniedRequest = 'responseDeniedRequest',
  ResponseMemberCode = 'responseMemberCode',
}

//  HOA HELPERS

//  PROVIDERS HELPERS

export const checkIfProviderExists = async (email: string, communityId?: string): Promise<boolean> => {
  const providersRef = collection(db, FIREBASE_USERSPROVIDERS_COLLECTION);
  const q = query(providersRef, where('email', '==', email));

  const providers = await getDocs(q);

  if (communityId === undefined) {
    return !providers.empty;
  }

  // Check if any provider has the specified community ID
  return providers.docs.some((providerDoc: QueryDocumentSnapshot<DocumentData>) => {
    const providerData = providerDoc.data();
    return providerData.community?.some((community: { id: string }) => community.id === communityId);
  });
};

//  HOMEOWNERS HELPERS

export const updateTemperatureOfProvidersInHomeowners = async (
  currentUserUid: string,
  arrayNuevaTemperatura: any[],
): Promise<void> => {
  await updateDoc(doc(db, FIREBASE_USERS_COLLECTION, currentUserUid), {
    myProviders: arrayNuevaTemperatura,
  });
};

interface UserRequestData {
  address: string;
  communityId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  message: string;
}

export const addUserRequest = async (data: UserRequestData): Promise<void> => {
  const homeownerRequestRef = collection(db, FIREBASE_USERREQUEST_COLLECTION);
  await addDoc(homeownerRequestRef, {
    address: data.address,
    communityId: data.communityId,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    request: data.message,
    state: 'pending',
  });
};

interface ProviderSuggestionData {
  name: string;
  phone: string;
  email: string;
  services: string[];
  comments: string;
  suggesterId?: string | undefined;
  timeStamp: string; // Adjust type if necessary
  mytemperature: number;
  recurrent: boolean;
}

export const createProviderSuggestion = async (
  suggestedProviderData: ProviderSuggestionData,
  images: File[],
): Promise<string> => {
  const invitationId = uuidv4();

  const docRef = doc(db, FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION, invitationId);
  try {
    await setDoc(docRef, suggestedProviderData);
    const newDocId = invitationId;

    if (images.length > 0) {
      await handleUploadFiles(newDocId, images, FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION);
    }
  } catch (error) {
    defaultErrorHandler('Error writing document: ', error as Error);
  }
  return invitationId;
};

// List of folders that require URL resolution
const PRIVATE_FOLDERS = ['ProviderTickets'] as const;

interface CacheEntry {
  url: string;
  timestamp: number;
}

const CACHE_TTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const CACHE_STORAGE_KEY = 'thermokracy_url_cache';

// Cache for storing resolved URLs with TTL
const urlCache = new Map<string, CacheEntry>();

const loadCacheFromStorage = (): void => {
  try {
    const storedCache = localStorage.getItem(CACHE_STORAGE_KEY);
    if (storedCache !== null && storedCache !== '') {
      const parsedCache = JSON.parse(storedCache) as Record<string, CacheEntry>;
      Object.entries(parsedCache).forEach(([key, value]) => {
        urlCache.set(key, value);
      });
      defaultDebugHandler('Loaded URL cache from localStorage');
    }
  } catch (error) {
    defaultErrorHandler('Error loading URL cache from localStorage:', error as Error);
  }
};

const saveCacheToStorage = (): void => {
  try {
    const cacheObject = Object.fromEntries(urlCache);
    localStorage.setItem(CACHE_STORAGE_KEY, JSON.stringify(cacheObject));
    defaultDebugHandler('Saved URL cache to localStorage');
  } catch (error) {
    defaultErrorHandler('Error saving URL cache to localStorage:', error as Error);
  }
};

const isCacheValid = (entry: CacheEntry): boolean => {
  return Date.now() - entry.timestamp < CACHE_TTL;
};

// Load cache when module initializes
loadCacheFromStorage();

const getImageUrl = async (path: string): Promise<string> => {
  const decodedPath = decodeURIComponent(path);

  // Check if URL is already in cache and valid
  const cachedEntry = urlCache.get(decodedPath);
  if (cachedEntry !== undefined && isCacheValid(cachedEntry)) {
    defaultDebugHandler(`Cache hit for path: ${decodedPath}`);
    return cachedEntry.url;
  }

  defaultDebugHandler(`Cache miss for path: ${decodedPath}, fetching from Firebase Storage`);
  try {
    const storage = getStorage();
    const storageRef = ref(storage, decodedPath);
    const url = await getDownloadURL(storageRef);

    // Store URL in cache with timestamp
    urlCache.set(decodedPath, {
      url,
      timestamp: Date.now(),
    });

    // Save updated cache to localStorage
    saveCacheToStorage();

    defaultDebugHandler(`Cached URL for path: ${decodedPath}`);
    return url;
  } catch (error) {
    defaultErrorHandler(`Error fetching URL for path: ${decodedPath}`, error as Error);
    // If we have a cached URL (even if expired), return it as fallback
    if (cachedEntry !== undefined) {
      defaultDebugHandler(`Using expired cache as fallback for path: ${decodedPath}`);
      return cachedEntry.url;
    }
    throw error;
  }
};

export const getDownloadUrls = async (paths: string[]): Promise<string[]> => {
  try {
    const urlPromises = paths.map(async (path) => {
      // Check if the path starts with any of our private folders
      const needsResolution = PRIVATE_FOLDERS.some((folder) => path.startsWith(folder));
      defaultDebugHandler(`Path: ${path}, needsResolution:`, needsResolution);

      if (!needsResolution) {
        return path;
      }

      return getImageUrl(path);
    });
    return await Promise.all(urlPromises);
  } catch (error) {
    defaultErrorHandler('Error getting download URLs:', error as Error);
    return [];
  }
};

//  REGISTRATION

export const retrieveAllCommunities = async (): Promise<Array<{ uid: string } & Community>> => {
  const communitiesRef = collection(db, FIREBASE_COMMUNITIES_COLLECTION);
  const communities = await getDocs(communitiesRef);
  return communities.docs.map((community: QueryDocumentSnapshot<DocumentData>) => {
    const data = community.data() as Community;
    return {
      uid: community.id,
      address: data.address ?? '',
      hoaAdmins: data.hoaAdmins ?? [],
      img: data.img ?? '',
      mail: data.mail ?? '',
      managementCompany: data.managementCompany ?? '',
      communityId: community.id,
      name: data.name ?? '',
      phone: data.phone ?? '',
    };
  });
};

interface NewCommunityRequestData {
  firstName: string;
  lastName: string;
  email: string;
  communityName: string;
  communityAddress: string;
  communityContactFirstName: string;
  communityContactLastName: string;
  communityEmail: string;
  communityPhone: string;
}
export const addRequestNewCommunity = async (data: NewCommunityRequestData): Promise<void> => {
  const newCommunityRequestRef = collection(db, FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION);
  await addDoc(newCommunityRequestRef, data);
};

//  GENERICS

export const getUserInfo = async (uid: string): Promise<QueryDocumentSnapshot<DocumentData> | null> => {
  const userInfo = await getDoc(doc(db, FIREBASE_USERS_COLLECTION, uid));

  if (userInfo.exists()) return userInfo;

  const providerUserInfo = await getDoc(doc(db, FIREBASE_USERSPROVIDERS_COLLECTION, uid));

  if (providerUserInfo.exists()) return providerUserInfo;

  const hoaUserInfo = await getDoc(doc(db, FIREBASE_USERSHOA_COLLECTION, uid));

  if (hoaUserInfo.exists()) return hoaUserInfo;

  return null;
};

interface EmailData {
  to: string[];
  template: {
    name: EmailTemplate;
    data: Record<string, any>;
  };
  attachments?: File[];
}

export const sendEmail = async (emailData: EmailData): Promise<void> => {
  // eslint-disable-next-line no-param-reassign
  emailData.template.data.environmentUrl = process.env.REACT_APP_DOMAIN;
  const outgoingEmailRef = collection(db, FIREBASE_OUTGOINGEMAIL_COLLECTION);
  await addDoc(outgoingEmailRef, emailData);
};
// TODO: Change to related providers
export const getSuggestedProviders = async (servicios: string[], id: string): Promise<Provider[]> => {
  const serviciosLimitados = servicios;

  const providersRef = collection(db, FIREBASE_USERSPROVIDERS_COLLECTION);

  const q = query(
    providersRef,
    where('services', 'array-contains-any', serviciosLimitados),
    orderBy('temperature', 'desc'),
    limit(4),
  );

  const proveedores = await getDocs(q);

  const proveedoresArray: Provider[] = [];

  proveedores.forEach((document: QueryDocumentSnapshot<DocumentData>) => {
    if (document.id !== id) {
      const proveedorData = document.data() as Provider;
      proveedorData.id = document.id;
      proveedoresArray.push(proveedorData);
    }
  });

  return proveedoresArray;
};

interface EmailValidationResult {
  exists: boolean;
  userType?: 'user' | 'hoa' | 'provider' | 'request';
}

export const validateEmail = async (email: string): Promise<EmailValidationResult> => {
  // Validar formato de email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return { exists: false };
  }

  // Buscar en users
  const usersRef = collection(db, FIREBASE_USERS_COLLECTION);
  const usersQuery = query(usersRef, where('email', '==', email));
  const usersSnapshot = await getDocs(usersQuery);
  if (!usersSnapshot.empty) {
    return { exists: true, userType: 'user' };
  }

  // Buscar en usersHOA
  const hoaRef = collection(db, FIREBASE_USERSHOA_COLLECTION);
  const hoaQuery = query(hoaRef, where('email', '==', email));
  const hoaSnapshot = await getDocs(hoaQuery);
  if (!hoaSnapshot.empty) {
    return { exists: true, userType: 'hoa' };
  }

  // Buscar en usersProviders
  const providersRef = collection(db, FIREBASE_USERSPROVIDERS_COLLECTION);
  const providersQuery = query(providersRef, where('email', '==', email));
  const providersSnapshot = await getDocs(providersQuery);
  if (!providersSnapshot.empty) {
    return { exists: true, userType: 'provider' };
  }

  // Buscar en userRequest
  const requestRef = collection(db, FIREBASE_USERREQUEST_COLLECTION);
  const requestQuery = query(requestRef, where('email', '==', email));
  const requestSnapshot = await getDocs(requestQuery);
  if (!requestSnapshot.empty) {
    return { exists: true, userType: 'request' };
  }

  return { exists: false };
};
