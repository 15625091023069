import { UserRole } from '@thermokracy/model';

// Re-export for compatibilyty with the old import
export { UserRole };

// TODO: Eliminar, esto son solo ejemplos funcionales
export const exampleProviderId = '036f16c9-ba00-4734-9a8a-93126ca2ad48';
export const exampleTicketId = 'di0dab6ONCx80wMstjFS';

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions: Array<{ id: string; name: string; direction: 'ltr' | 'rtl' }> = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyAx5ndLJchuhkT09f93Lmhz9y6tN1lqyoM',
  authDomain: 'thermokracy-dev.firebaseapp.com',
  projectId: 'thermokracy-dev',
  storageBucket: 'thermokracy-dev.appspot.com',
  messagingSenderId: '774317097518',
  appId: '1:774317097518:web:bc9e64a2ee7c733a0555bf',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  role: UserRole.Homeowner,
};

// TODO: Necesitamos las variables para multiples temas?
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

//  Constante envio mails
export const AdminMail = 'dpuntero@gmail.com';

// Notification Images
export const RoofmanIcon =
  'https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/Tickets%2Froofman.jfif?alt=media&token';
export const GenericIcon =
  'https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/Tickets%2Fgeneric.jfif?alt=media&token';
export const defaultProviderCarrouselImage = `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/ProviderImages%2Fdefault%2F1.jfif?alt=media&token`;
