import * as model from '@thermokracy/model';

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_USERS_COLLECTION = model.COLLECTION_USERS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_USERSHOA_COLLECTION = model.COLLECTION_USERS_HOA;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_USERSPROVIDERS_COLLECTION = model.COLLECTION_USERS_PROVIDERS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_COMMUNITIES_COLLECTION = model.COLLECTION_COMMUNITIES;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_HOAPROVIDERS_COLLECTION = model.COLLECTION_HOA_PROVIDERS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_MASSIVEMESSAGESHOATOHOMEOWNER_COLLECTION = model.COLLECTION_MASSIVE_MESSAGES_HOA_TO_HOMEOWNER;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION = model.COLLECTION_NEW_COMMUNITY_REQUEST;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION = model.COLLECTION_NEW_PROVIDER_SUGGESTION;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_NOTIFICATIONS_COLLECTION = model.COLLECTION_NOTIFICATIONS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_OUTGOINGEMAIL_COLLECTION = model.COLLECTION_EMAIL_OUTGOING;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TICKETS_COLLECTION = model.COLLECTION_TICKETS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TICKETSTOPROVIDERS_COLLECTION = model.COLLECTION_PROVIDERS_TICKETS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_USERREQUEST_COLLECTION = model.COLLECTION_USER_REQUEST;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_PERSONALCODE_COLLECTION = model.COLLECTION_PERSONAL_CODE;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TICKETTOPICS_COLLECTION = model.COLLECTION_TICKET_TOPICS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_HOAINVITATIONS_COLLECTION = model.COLLECTION_HOA_INVITATIONS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_REQUESTDELETEACCOUNT_COLLECTION = model.COLLECTION_REQUEST_DELETE_ACCOUNT;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_NEWHOMEOWNER_INVITATION = model.COLLECTION_NEW_HOMEOWNER_INVITATION;

// Tracking Collections
/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TRACKCHANGESTEMPERATURE_COLLECTION = model.COLLECTION_TRACK_CHANGES_TEMPERATURE;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TRACKINTERESDEUSUARIOS_COLLECTION = model.COLLECTION_TRACK_INTERES_DE_USUARIOS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TRACKREVIEWSOFPROVIDERS_COLLECTION = model.COLLECTION_TRACK_REVIEWS_OF_PROVIDERS;

/** @deprecated import from @thermokracy/model instead */
export const FIREBASE_TRACKACCOUNTMANAGEMENT_COLLECTION = model.COLLECTION_TRACK_ACCOUNT_MANAGEMENT;
