import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from 'helpers/Firebase';
import {
  COLLECTION_TICKETS,
  COLLECTION_COMMUNITIES,
  type HoaTicket,
  type TicketComment,
  type TicketCategory,
} from '@thermokracy/model';

import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  writeBatch,
  type DocumentData,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, handleUploadFiles } from 'helpers/genericTsHelpers';
import { FirebaseError } from 'firebase/app';

interface TicketState {
  tickets: HoaTicket[];
  categories: TicketCategory[];
  configuredCategories: string[];
  error: string;
  ticketsLoading: boolean;
  configuredCategoriesLoading: boolean;
  isDeleting: boolean;
}

const initialState: TicketState = {
  tickets: [],
  categories: [],
  configuredCategories: [],
  error: '',
  ticketsLoading: true,
  configuredCategoriesLoading: true,
  isDeleting: false,
};

export const orderColumns = [
  { column: 'title', label: 'Title' },
  { column: 'category', label: 'Category' },
  { column: 'status', label: 'Status' },
];

const ConvertFirebaseDocumentToTicket = (docu: DocumentData, id: string): HoaTicket => {
  const {
    communityId,
    openerId,
    openerName,
    title,
    description,
    status,
    category,
    createDate,
    comments = [],
    idProviderHOA,
    nameProviderHOA,
    carpeta,
  } = docu;
  const formattedCreateDate = createDate.toDate();
  const cdate = `${formattedCreateDate.getMonth() + 1}/${formattedCreateDate.getDate()}/${formattedCreateDate.getFullYear()}`;

  const ccomments: TicketComment[] = comments.map((comment: TicketComment) => {
    const commentDate = createDate.toDate();
    return {
      ...comment,
      date: `${commentDate.getMonth() + 1}/${commentDate.getDate()}/${commentDate.getFullYear()}`,
    };
  });

  return {
    communityId,
    openerId,
    openerName,
    title,
    description,
    status,
    category,
    id,
    createDate: cdate,
    comments: ccomments,
    idProviderHOA: idProviderHOA ?? '',
    nameProviderHOA: nameProviderHOA ?? '',
    carpeta,
  };
};

interface GetTicketeListParams {
  communityId: string;
  openerId?: string;
}

export const getTicketList = createAsyncThunk<HoaTicket[], GetTicketeListParams, { rejectValue: { error: string } }>(
  'ticketsT/getTicketList',
  async ({ communityId, openerId }: GetTicketeListParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, COLLECTION_TICKETS);
      const q = isEmpty(openerId)
        ? query(ticketsRef, where('communityId', '==', communityId))
        : query(ticketsRef, where('communityId', '==', communityId), where('openerId', '==', openerId));
      const ticketsSnapshot = await getDocs(q);
      const documents = ticketsSnapshot.docs.map((docu) => {
        return ConvertFirebaseDocumentToTicket(docu.data(), docu.id);
      });

      return documents;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface ConfiguredCategoriesParams {
  communityId: string;
}

export const getConfiguredCategories = createAsyncThunk<
  string[],
  ConfiguredCategoriesParams,
  { rejectValue: { error: string } }
>('ticketsT/getConfiguredCategories', async ({ communityId }: ConfiguredCategoriesParams, { rejectWithValue }) => {
  try {
    const ticketsRef = collection(db, COLLECTION_COMMUNITIES);
    const ticketDocRef = doc(ticketsRef, communityId);
    const docSnapshot = await getDoc(ticketDocRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.configuration?.availableCategories ?? [];
    }
    return [];
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      return rejectWithValue({ error: error.message });
    }
    return rejectWithValue({ error: 'An unknown error occurred' });
  }
});

interface AddTicketItemParams {
  ticket: HoaTicket;
  selectedFiles: File[];
}

export const addTicketItem = createAsyncThunk<HoaTicket, AddTicketItemParams, { rejectValue: { error: string } }>(
  'ticketsT/addTicketItem',
  async ({ ticket, selectedFiles }: AddTicketItemParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, COLLECTION_TICKETS);
      const newDoc = await addDoc(ticketsRef, {
        ...ticket,
        createDate: serverTimestamp(),
      });

      const newDocId = newDoc.id;
      const docRefImages = doc(db, COLLECTION_TICKETS, newDocId);

      if (selectedFiles.length > 0) {
        const newFieldData = {
          carpeta: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/Tickets%2F${newDocId}`,
        };
        await updateDoc(docRefImages, newFieldData);
        await handleUploadFiles(newDocId, selectedFiles, COLLECTION_TICKETS);
      }

      return { ...ticket, id: newDocId };
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface DeleteTicketItemArgs {
  ticketsToDelete: Array<{ title: string; id: string }>;
}

export const deleteTicketItem = createAsyncThunk(
  'tickets/deleteTicketItem',
  async ({ ticketsToDelete }: DeleteTicketItemArgs, { rejectWithValue }) => {
    try {
      const batch = writeBatch(db);
      const ticketsRef = collection(db, COLLECTION_TICKETS);
      const q = query(
        ticketsRef,
        where(
          '__name__',
          'in',
          ticketsToDelete.map((ticket) => ticket.id),
        ),
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length === 0) {
        throw new Error('No documents to delete');
      }
      querySnapshot.forEach((docu) => {
        const docRef = doc(ticketsRef, docu.id);
        batch.delete(docRef);
      });

      await batch.commit();
      return { status: 'success' };
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface GetTicketDetailsParams {
  ticketId: string;
}
export const getTicketDetails = createAsyncThunk<HoaTicket, GetTicketDetailsParams, { rejectValue: { error: string } }>(
  'tickets/getTicketDetails',
  async ({ ticketId }: GetTicketDetailsParams, { rejectWithValue }) => {
    try {
      const ticketRef = doc(db, COLLECTION_TICKETS, ticketId);
      const ticketDoc = await getDoc(ticketRef);
      if (ticketDoc.exists()) {
        return ConvertFirebaseDocumentToTicket(ticketDoc.data(), ticketDoc.id);
      }
      return rejectWithValue({ error: 'Ticket does not exist' });
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);
interface AddConfiguredCategoryParams {
  communityId: string;
  category: string;
}

export const addConfiguredCategory = createAsyncThunk(
  'tickets/addConfiguredCategory',
  async ({ communityId, category }: AddConfiguredCategoryParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, COLLECTION_COMMUNITIES);
      const ticketDocRef = doc(ticketsRef, communityId);
      const docSnapshot = await getDoc(ticketDocRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const categories = Array.isArray(data.configuration?.availableCategories)
          ? (data.configuration?.availableCategories as string[])
          : [];

        // Check if the category already exists to avoid duplicates
        if (typeof category === 'string' && !categories.includes(category)) {
          const updatedCategories = [...categories, category];

          // Update the document with the new array of categories
          await updateDoc(ticketDocRef, {
            'configuration.availableCategories': updatedCategories,
          });
        }
      } else {
        return { success: false };
      }
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
    return { success: true };
  },
);

interface AddCommentParams {
  ticketId: string;
  comment: string;
  commenterId: string;
  commenterName: string;
  commenterImg: string;
}

export const addComment = createAsyncThunk<HoaTicket, AddCommentParams, { rejectValue: { error: string } }>(
  'tickets/addComment',
  async ({ ticketId, comment, commenterId, commenterName, commenterImg }: AddCommentParams, { rejectWithValue }) => {
    const commentId = uuidv4();
    try {
      const ticketRef = doc(db, COLLECTION_TICKETS, ticketId);
      const ticketDoc = await getDoc(ticketRef);
      if (ticketDoc.exists()) {
        const ticket = ConvertFirebaseDocumentToTicket(ticketDoc.data(), ticketDoc.id);
        const newComments = [
          ...(ticket.comments ?? []),
          {
            content: comment,
            date: new Date().toLocaleDateString(),
            commentId,
            commenter: {
              commenterId,
              commenterName,
              commenterImg,
            },
          },
        ];

        await updateDoc(ticketRef, {
          comments: newComments,
        });
        return { ...ticket, comments: newComments };
      }
      throw new Error('No such document!');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface DeleteCommentParams {
  ticketId: string;
  commentId: string;
}

export const deleteComment = createAsyncThunk<
  { status: string },
  DeleteCommentParams,
  { rejectValue: { error: string } }
>('tickets/addComment', async ({ ticketId, commentId }: DeleteCommentParams, { rejectWithValue }) => {
  try {
    const ticketRef = doc(db, COLLECTION_TICKETS, ticketId);
    const ticketDoc = await getDoc(ticketRef);
    if (ticketDoc.exists()) {
      const updatedComments = (ticketDoc.data().comments as TicketComment[]).filter(
        (comment: TicketComment) => comment.commentId !== commentId,
      );
      await updateDoc(ticketRef, { comments: updatedComments });

      return { status: 'success' };
    }

    throw new Error('No such document!');
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      return rejectWithValue({ error: error.message });
    }
    return rejectWithValue({ error: 'An unknown error occurred' });
  }
});

interface UpdateTicketFieldsParams {
  ticketIds: string;
  fieldsToUpdate: Partial<HoaTicket>; // Partial allows updating one or more fields of the ticket
}

export const updateTicketFields = createAsyncThunk<
  HoaTicket,
  UpdateTicketFieldsParams,
  { rejectValue: { error: string } }
>(
  'tickets/updateTicketFields',
  async ({ ticketIds: ticketId, fieldsToUpdate }: UpdateTicketFieldsParams, { rejectWithValue }) => {
    try {
      const ticketDocRef = doc(db, COLLECTION_TICKETS, ticketId);

      await updateDoc(ticketDocRef, fieldsToUpdate);

      const ticketDocSnapshot = await getDoc(ticketDocRef);
      const ticket = ticketDocSnapshot.data();
      const updatedTicketDetails: Record<string, unknown> = { ...ticket, ...fieldsToUpdate };

      return ConvertFirebaseDocumentToTicket(updatedTicketDetails, ticketId); // Assuming fixFirebaseData is a function that processes the data as needed
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface UpdateTicketFieldsParamsMultiple {
  ticketIds: string[];
  fieldsToUpdate: Partial<HoaTicket>; // Partial allows updating one or more fields of the ticket
}
export const updateTicketFieldsMultiple = createAsyncThunk<
  HoaTicket[],
  UpdateTicketFieldsParamsMultiple,
  { rejectValue: { error: string } }
>(
  'tickets/updateTicketFieldsMultiple',
  async ({ ticketIds, fieldsToUpdate }: UpdateTicketFieldsParamsMultiple, { rejectWithValue }) => {
    try {
      const batch = writeBatch(db);

      ticketIds.forEach((ticketId) => {
        const ticketDocRef = doc(db, COLLECTION_TICKETS, ticketId);
        const updatedTicketDetails = { ...fieldsToUpdate };
        batch.update(ticketDocRef, updatedTicketDetails);
      });

      await batch.commit();

      // Assuming ConvertFirebaseDocumentToTicket is a function that processes the data as needed
      const updatedTickets = ticketIds.map((ticketId) =>
        ConvertFirebaseDocumentToTicket(fieldsToUpdate as Record<string, unknown>, ticketId),
      );

      return updatedTickets;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface DeleteConfiguredCategoriesParams {
  communityId: string;
  categories: string[];
}

export const deleteConfiguredCategories = createAsyncThunk(
  'tickets/deleteConfiguredCategories',
  async ({ communityId, categories }: DeleteConfiguredCategoriesParams, { rejectWithValue }) => {
    try {
      // Primero verificamos si hay tickets usando estas categorías
      const ticketsRef = collection(db, COLLECTION_TICKETS);
      const q = query(ticketsRef, where('communityId', '==', communityId));
      const ticketsSnapshot = await getDocs(q);

      const ticketsUsingCategories = ticketsSnapshot.docs
        .map((doc) => ConvertFirebaseDocumentToTicket(doc.data(), doc.id))
        .filter((ticket) => categories.includes(ticket.category));

      if (ticketsUsingCategories.length > 0) {
        const categoriesInUse = Array.from(new Set(ticketsUsingCategories.map((ticket) => ticket.category)));
        return rejectWithValue({
          error: `Cannot delete the following categories because they have associated tickets: ${categoriesInUse.join(', ')}`,
        });
      }

      // Si no hay tickets usando las categorías, procedemos a borrarlas
      const communitiesRef = collection(db, COLLECTION_COMMUNITIES);
      const communityDocRef = doc(communitiesRef, communityId);
      const docSnapshot = await getDoc(communityDocRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const currentCategories = Array.isArray(data.configuration?.availableCategories)
          ? (data.configuration?.availableCategories as string[])
          : [];

        const updatedCategories = currentCategories.filter((category) => !categories.includes(category));

        await updateDoc(communityDocRef, {
          'configuration.availableCategories': updatedCategories,
        });
      }

      return { success: true };
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

const ticketsSliceT = createSlice({
  name: 'ticketsT',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTicketList.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(getTicketList.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
        const allCategories: string[] = action.payload.reduce((acc: string[], ticket: HoaTicket) => {
          return acc.concat(ticket.category);
        }, []);
        state.categories = Array.from(new Set(allCategories)).map((category) => ({ name: category }));
      })
      .addCase(getTicketList.rejected, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addTicketItem.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(addTicketItem.fulfilled, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addTicketItem.rejected, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(getConfiguredCategories.pending, (state) => {
        state.configuredCategoriesLoading = true;
      })
      .addCase(getConfiguredCategories.fulfilled, (state, action) => {
        state.configuredCategories = action.payload;
        state.configuredCategoriesLoading = false;
      })
      .addCase(getConfiguredCategories.rejected, (state) => {
        state.configuredCategories = [];
        state.configuredCategoriesLoading = false;
      })
      .addCase(deleteConfiguredCategories.pending, (state) => {
        state.configuredCategoriesLoading = true;
      })
      .addCase(deleteConfiguredCategories.fulfilled, (state) => {
        state.configuredCategoriesLoading = false;
      })
      .addCase(deleteConfiguredCategories.rejected, (state, action) => {
        state.configuredCategoriesLoading = false;
        state.error = (action.payload as { error: string })?.error ?? 'An unknown error occurred';
      });
  },
});
export default ticketsSliceT.reducer;
