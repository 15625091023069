export const COLLECTION_NEW_PROVIDER_SUGGESTION = 'newProviderSuggestions';
export const COLLECTION_USERS_PROVIDERS = 'UsersProviders';
// TODO: Homeowner table, might want to rename to UsersHomeowner
export const COLLECTION_USERS = 'Users';
export const COLLECTION_NEW_HOMEOWNER_INVITATION = 'newHomeownerInvitation';
export const COLLECTION_REVIEWS_PROVIDERS = 'LogReviewsOfProviders';
export const COLLECTION_USERS_HOA = 'UsersHoa';
export const COLLECTION_COMMUNITIES = 'Communities';
export const COLLECTION_HOA_PROVIDERS = 'HOAProviders';
export const COLLECTION_MASSIVE_MESSAGES_HOA_TO_HOMEOWNER = 'MassiveMessagesHOAtoHomeowner';
export const COLLECTION_NEW_COMMUNITY_REQUEST = 'NewCommunityRequest';
export const COLLECTION_NOTIFICATIONS = 'Notifications';
export const COLLECTION_EMAIL_OUTGOING = 'EmailOutgoing';
export const COLLECTION_TICKETS = 'Tickets';
export const COLLECTION_PROVIDERS_TICKETS = 'ProviderTickets';
export const COLLECTION_USER_REQUEST = 'UserRequest';
export const COLLECTION_PERSONAL_CODE = 'PersonalCode';
export const COLLECTION_TICKET_TOPICS = 'TicketTopics';
export const COLLECTION_HOA_INVITATIONS = 'HOAInvitations';
export const COLLECTION_REQUEST_DELETE_ACCOUNT = 'RequestDeleteAccount';

// Tracking Collections
export const COLLECTION_TRACK_CHANGES_TEMPERATURE = 'TrackChangesTemperature';
export const COLLECTION_TRACK_INTERES_DE_USUARIOS = 'TrackInteresDeUsuarios';
export const COLLECTION_TRACK_REVIEWS_OF_PROVIDERS = 'TrackReviewsOfProviders';
export const COLLECTION_TRACK_ACCOUNT_MANAGEMENT = 'TrackAccountManagement';
