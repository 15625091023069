export interface Ticket {
  communityId: string;
  openerId: string;
  openerName: string;
  category: string;
  createDate: string;
  title: string;
  description: string;
  id: string;
}

export interface Commenter {
  commenterId: string;
  commenterImg: string;
  commenterName: string;
}

export interface TicketComment {
  commentId: string;
  commenter: Commenter;
  content: string;
  date: string;
}

export interface TicketCategory {
  name: string;
}

// Provider Ticket extension
export type ProviderTicketStatusValue = 'PENDING' | 'APPROVING' | 'COMPLETED';

export const ProviderTicketStatus: Readonly<{ [v in ProviderTicketStatusValue]: v }> = Object.freeze({
  PENDING: 'PENDING',
  APPROVING: 'APPROVING',
  COMPLETED: 'COMPLETED',
});

// Tickets opened to ta provider from Homeowner? (what about HOA?)
export interface ProviderTicket extends Ticket {
  providerId: string;
  providerName: string;
  status: keyof typeof ProviderTicketStatus;
  extraInfo?: string;
  createDate: string;
  images?: string[];
}

// HOA Ticket extension
export type HoaTicketStatusValue = 'OPEN' | 'SOLVED' | 'PENDING' | 'CLOSED' | 'REFRESH';

export const HoaTicketStatus: Readonly<{ [v in HoaTicketStatusValue]: v }> = Object.freeze({
  OPEN: 'OPEN',
  SOLVED: 'SOLVED',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
  REFRESH: 'REFRESH',
});

// Tickets opened to a provider from a Homeowner
export interface HoaTicket extends Ticket {
  nameProviderHOA?: string;
  idProviderHOA?: string;
  comments?: TicketComment[];
  carpeta?: string;
  status: keyof typeof HoaTicketStatus;
}

// Fixed Ticket Topics
export interface TicketTopics {
  topics: string[];
}
